import "../src/index.css";
import "@fontsource/inter";
import "@fontsource/inter/300.css";
import Hallbarhet from "./pages/Hallbarhet";
import Home from "./pages/Home";
import { Route, Routes } from "react-router-dom";
import Nyheter from "./pages/Nyheter";
import Article from "./pages/Article";
import Service from "./pages/Service";
import Kontakt from "./pages/Kontakt";

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/hallbarhet" element={<Hallbarhet />} />
      <Route path="/nyheter" element={<Nyheter />} />
      <Route path="/nyheter/:id" element={<Article />} />
      <Route path="/tjanster" element={<Service />} />
      <Route path="/kontakt" element={<Kontakt />} />
      <Route path="/jobba-med-ss/:id" element={<Article />} />
    </Routes>
  );
}
