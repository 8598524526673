import React from "react";
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const Content = ({ content }) => {
  // const options = {
  //   renderNode: {
  //     [INLINES.HYPERLINK]: ({ data }, children) => (
  //       <a
  //         href={data.uri}
  //         target={data.uri}
  //         rel={data.uri}
  //         className="text-blue-500 underline"
  //       >
  //         {children}
  //       </a>
  //     ),
  //     [BLOCKS.UL_LIST]: ({ content }) =>
  //       content.map(({ content }) => <li>{content[0]?.content[0]?.value}</li>),
  //   },
  // };

  const options = {
    renderMark: {
      [MARKS.BOLD]: (node) => <span className="font-[500]">{node}</span>,
    },
    renderNode: {
      [INLINES.HYPERLINK]: ({ data }, children) => {
        return (
          <a
            href={data.uri}
            target="_blank"
            rel={data.uri}
            className="text-blue-500 underline"
          >
            {children}
          </a>
        );
      },
      [BLOCKS.HEADING_2]: (node, children) => {
        return <div className="font-[500]">{children}</div>;
      },
      [BLOCKS.HEADING_3]: (node, children) => {
        return <div className="font-[500]">{children}</div>;
      },
      [BLOCKS.HEADING_4]: (node, children) => {
        return <div className="font-[500]">{children}</div>;
      },
      [BLOCKS.HEADING_5]: (node, children) => {
        return <div className="font-[500]">{children}</div>;
      },
      [BLOCKS.UL_LIST]: (node, children) => (
        <ul className="unordered-list">{children}</ul>
      ),
      [BLOCKS.OL_LIST]: (node, children) => (
        <ol className="ordered-list">{children}</ol>
      ),
    },
  };

  return (
    <div className="p-5 pb-20 md:p-16 lg:px-28   w-full 2xl:p-10 2xl:pl-28  ">
      <div className="max-w-[1188px]">
        <h3 className=" hidden md:block  text-[20px] font-sans font-[500]  md:text-[24px] md:font-[700] mb-6  ">
          {content?.articleSectionPara?.content[0]?.content[0]?.value}
        </h3>
        <h3 className=" md:hidden text-[20px] font-sans font-[500]  md:text-[24px] md:font-[700] mb-6  ">
          {content?.mobilefirsttext}
        </h3>
        <div className="text-[16px] md:text-[20px] font-[400] mb-8 ">
          <h3 className=" font-[700] mb-3 ">{content?.section1Title}</h3>
          {documentToReactComponents(content?.section1Para, options)}
        </div>
        <div className="text-[16px] md:text-[20px] font-[400] mb-8 ">
          <h3 className=" font-[700] mb-3 ">{content?.section2Title}</h3>
          {documentToReactComponents(content?.section2Para, options)}
        </div>
        <div className="text-[16px] md:text-[20px] font-[400] mb-8 ">
          <h3 className=" font-[700] mb-3 ">{content?.section3Title}</h3>
          {documentToReactComponents(content?.section3Para, options)}
        </div>{" "}
        <div className="text-[16px] md:text-[20px] font-[400] mb-8 ">
          <h3 className=" font-[700] mb-3 ">{content?.section4Title}</h3>
          <p>{documentToReactComponents(content?.section4Para, options)}</p>
        </div>{" "}
        <div className="text-[16px] md:text-[20px] font-[400] mb-8 ">
          <h3 className=" font-[700] mb-3 ">{content?.section5Title}</h3>
          {documentToReactComponents(content?.section5Para, options)}
        </div>{" "}
        <div className="text-[16px] md:text-[20px] font-[400] mb-8 ">
          <h3 className=" font-[700] mb-3 ">{content?.section6Title}</h3>
          {documentToReactComponents(content?.section6Para, options)}
        </div>{" "}
        <div className="text-[16px] md:text-[20px] font-[400] mb-8 ">
          <h3 className=" font-[700] mb-3 ">{content?.section7Title}</h3>
          {documentToReactComponents(content?.section7Para, options)}
        </div>
        <div className="text-[16px] md:text-[20px] font-[400] mb-8 ">
          <h3 className=" font-[700] mb-3 ">{content?.section8Title}</h3>
          {documentToReactComponents(content?.section8Para, options)}
        </div>
      </div>
    </div>
  );
};

export default Content;
