import React, { useEffect, useState } from "react";
import emLogo from "../../assets/images/logo-w.png";
import one from "../../assets/images/bg-one.jpg";
import two from "../../assets/images/bg-two.jpg";
import three from "../../assets/images/bg-three.jpg";
import iconOne from "../../assets/images/icon-one.png";
import iconTwo from "../../assets/images/icon-two.png";
import iconThree from "../../assets/images/icon-three.png";
import iconTwoW from "../../assets/images/icon-two-w.png";
import iconThreeW from "../../assets/images/icon-three-w.png";
import arrowCercle from "../../assets/images/big-arrow.png";
import arrow from "../../assets/images/arrow-w.png";
import { useNavigate } from "react-router-dom";
const datas = [
  {
    title: "Nybyggnation",
    icon: iconOne,
    w: 295,
    h: 140,
    bg: one,
    hover: iconThreeW,
    dec: `Vid nybyggnation av bostäder, kommersiella lokaler eller kontor är kvalitetsinstallationer för el och datanätverk avgörande. Egestam & Mattsson erbjuder hållbara och energieffektiva lösningar inom allt från belysning till avancerade datanätverk och smarta system, vilket skapar en säker och framtidssäkrad miljö. Kontakta oss för kvalitetslösningar du kan lita på.`,
  },
  {
    title: "Renovering",
    icon: iconTwo,
    w: 106,
    h: 106,
    bg: two,
    hover: iconTwoW,
    dec: `Egestam och Mattsson erbjuder kompletta el- och installationstjänster för renovering av bostäder och kommersiella fastigheter. Vi uppgraderar befintliga elsystem och installerar moderna, energieffektiva lösningar, där funktion möter estetik. Genom nära samarbete säkerställer vi att alla projekt uppfyller kundens behov, med fokus på säkerhet, hållbarhet och innovation. Kontakta oss för en skräddarsydd offert från branschens experter.`,
  },
  {
    title: "Installation",
    icon: iconThree,
    w: 106,
    h: 106,
    bg: three,
    hover: iconThree,
    dec: `Egestam och Mattsson erbjuder skräddarsydda installationstjänster för moderna värmesystem, smarta hem, kontorslösningar, säkerhetssystem och datainstallationer. Vi säkerställer komfort, energieffektivitet och säkerhet i varje projekt genom ett nära samarbete och fokus på kundens behov. Vårt expertteam levererar noggrant planerade, hållbara lösningar för en intelligent och energismart miljö. 
Kontakta oss för en kostnadsfri offert.`,
  },
];

const LogoSection = () => {
  const navigate = useNavigate();
  const [slide, setSlide] = useState(0);
  const [enter, setEnter] = useState(false);
  const [show, setShow] = useState();

  useEffect(() => {
    const interval = setInterval(() => {
      setSlide((prevSlide) => (prevSlide + 1) % 3); // Loop from 0 to 2
    }, 5000);

    return () => clearInterval(interval); // Clear interval on component unmount
  }, []);

  return (
    <>
      <div className="relative w-full h-[331px] xl:h-[452px]">
        <div
          className={`w-full h-full bg-[#00354E] flex  justify-center items-center duration-700  absolute ${
            slide === 0 ? "opacity-100" : "opacity-0"
          } `}
        >
          <img
            src={emLogo}
            width={295}
            height={187}
            alt="logo"
            className="w-[200px] h-[120px] lg:w-[295px] lg:h-[187px] object-contain "
          />
        </div>
        <div
          className={` py-10 md:py-14 xl:py-20  px-6 md:bg-[#FFFFFF] flex flex-col md:flex-row justify-center  items-center gap-5 lg:gap-10   w-full h-full duration-700  absolute opacity-0 ${
            slide === 1 ? "opacity-100" : "opacity-0"
          }  `}
        >
          <img
            src={arrowCercle}
            alt={arrowCercle}
            width={296}
            height={296}
            className="w-[100px] md:w-[140px] md:h-[140px] lg:w-[196px] lg:h-[196px] 2xl:w-[296px] 2xl:h-[296px] object-contain"
          />

          <div className=" flex flex-col items-center md:items-start lg:max-w-[1165px] ">
            <h2 className="  font-[700] text-[18px] md:text-[24px] lg:text-[32px] 2xl:text-[40px]  font-sans ">
              Välkommen till EM EL & DATA AB{" "}
            </h2>

            <h3 className=" max-w-[700px] 2xl:max-w-[1165px] text-center md:text-start text-[12px] md:text-[14px] 2xl:text-[24px]  mt-4 md:mt-0  lg:mt-4  2xl:leading-[45px]">
              Egestam & Mattsson, grundat 2012, är experter inom el- och
              datanätverksinstallationer. Vi är ett entreprenörsdrivet företag
              framdrivet av ett team engagerade medarbetare. Vi kombinerar
              gedigen teknisk kunskap med ett starkt engagemang för hållbarhet
              och är fokuserade på att leverera miljövänliga, innovativa
              lösningar för fastigheter och infrastruktur
            </h3>
          </div>
        </div>
        <div
          className={` py-10 md:py-14 xl:py-20  px-6 bg-[#00354E] text-white flex flex-col md:flex-row justify-center  items-center gap-5 lg:gap-10   w-full h-full duration-700  absolute opacity-0 ${
            slide === 2 ? "opacity-100" : "opacity-0"
          }  `}
        >
          <img
            src={arrow}
            alt={arrowCercle}
            width={296}
            height={296}
            className="w-[100px] md:w-[140px] md:h-[140px] lg:w-[196px] lg:h-[196px] 2xl:w-[296px] 2xl:h-[296px] object-contain"
          />

          <div className=" flex flex-col items-center md:items-start lg:max-w-[1165px] ">
            <h2 className="  font-[700] text-[18px] md:text-[24px] lg:text-[32px] 2xl:text-[40px]  font-sans ">
              Välkommen till EM EL & DATA AB{" "}
            </h2>

            <h3 className=" max-w-[700px] 2xl:max-w-[1165px] text-center md:text-start text-[12px] md:text-[14px] 2xl:text-[24px]  mt-4 md:mt-0  lg:mt-4  2xl:leading-[45px]">
              Egestam & Mattsson, grundat 2012, är experter inom el- och
              datanätverksinstallationer. Vi är ett entreprenörsdrivet företag
              framdrivet av ett team engagerade medarbetare. Vi kombinerar
              gedigen teknisk kunskap med ett starkt engagemang för hållbarhet
              och är fokuserade på att leverera miljövänliga, innovativa
              lösningar för fastigheter och infrastruktur
            </h3>
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row">
        {datas.map((data, i) => (
          <div
            key={i}
            className="group md:flex-1 h-[300px] md:h-[273px] lg:h-[373px] bg-cover bg-no-repeat  flex justify-center items-center relative cursor-pointer "
            style={{
              backgroundImage: `url(${data.bg})`,
            }}
            onMouseEnter={() => setEnter(i)}
            onMouseLeave={() => setEnter(null)}
            onClick={() => setShow(data)}
          >
            <div
              className={`w-full h-full absolute top-0 left-0 bg-white ${
                show?.title !== data?.title && "group-hover:bg-[#00354E] "
              } opacity-50 ${
                i === 1 &&
                show?.title !== data?.title &&
                "group-hover:bg-[#89BF50CC]"
              } `}
            />
            {show?.title === data?.title ? (
              <div className="z-[1] w-full h-full flex flex-col justify-center items-center text-[#00354E] bg-white bg-opacity-70">
                <div className=" text-[18px] lg:text-[48px] font-semibold ">
                  {data.title}
                </div>
                <p className="text-center  text-[12px] lg:text-[14px] 2xl:text-[16px] px-3 md:leading-4 lg:leading-normal">
                  {data.dec}
                </p>
                <button
                  className=" text-[10px] md:text-[10px] lg:text-[12px] xl:text-[16px] uppercase font-bold bg-[#00354E] rounded-[14px] text-white px-8 lg:px-12 py-2 lg:py-4 mt-1  lg:mt-3 2xl:mt-5"
                  onClick={() => navigate("/kontakt")}
                >
                  Kontakta oss
                </button>
              </div>
            ) : (
              <div className="text-[#00354E]  z-[1] flex justify-center items-center flex-col ">
                <img
                  src={enter === i ? data.hover : data.icon}
                  width={data.w}
                  height={data.h}
                  className="px-5 object-contain"
                  alt=""
                />
                <div className=" text-[28px] lg:text-[48px] group-hover:text-white">
                  {data?.title}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default LogoSection;
