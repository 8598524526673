import React from "react";
import HeroSection from "../components/home/HeroSection";
import Välkommen from "../components/home/VälkommenSection";
import DropDownSection from "../components/home/DropDownSection";
import NordenSection from "../components/home/NordenSection";
import EnergieffektivaSection from "../components/home/EnergieffektivaSection";
import VartSection from "../components/home/VartSection";
import VartMotoSectionMDLG from "../components/home/VartMotoSectionMDLG";
import NyheterSection from "../components/home/NyheterSection";
import ProjektSection from "../components/home/ProjektSection";
import EgestamSection from "../components/home/EgestamSection";
import FooterSection from "../components/FooterSection";
import ScrollToTop from "../components/scrollToTop";
import { getMetaDescription } from "../utils/metaDescriptions";
import { Helmet } from "react-helmet";
import LogoSection from "../components/home/LogoSection";

const Home = () => {
  const metaDescription = getMetaDescription("home");
  return (
    <>
      <Helmet>
        <meta name="description" content={metaDescription} />
      </Helmet>
      <ScrollToTop />
      <HeroSection />
      <LogoSection />
      {/* <div className="w-full md:hidden z-10">
        <DropDownSection />
      </div> */}
      {/* <Välkommen /> */}
      {/* <div className="w-full hidden md:block">
        <DropDownSection />
      </div> */}
      {/* <NordenSection /> */}
      <EnergieffektivaSection />
      <VartSection />
      <VartMotoSectionMDLG />
      <NyheterSection />
      <div className="lg:px-11 2xl:pl-28">
        <ProjektSection />
      </div>
      <EgestamSection />
      <FooterSection />
    </>
  );
};

export default Home;
